// videoConferences:computedLonely:update
import stores from 'bootstrap/store';

import { EVENT } from '../../constants';


export default ({ apiWebSocket }) => {
  const videoConferencesStore = stores.global.videoConferences;

  apiWebSocket.on(EVENT.RECEIVED.VIDEO_CONFERENCE_COMPUTED_LONELY_UPDATE, () => {
    console.log('LONELY UPDATEEE');
    videoConferencesStore.listActive();
  });
};
