import { io } from 'socket.io-client';
import { API_BASE } from 'constants/index';

export const apiWebSocket = io(API_BASE, {
  // Reconnection settings
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  
  // Transport settings
  transports: ['websocket', 'polling'],  // Try websocket first
  upgrade: true,  // Allow transport upgrades
  
  // Timeout settings
  timeout: 20000,  // Connection timeout
  
  // Performance & reliability
  forceNew: false,  // Reuse existing connection if available
  multiplex: true,  // Allow connection sharing
  
  withCredentials: true,  // Only if your server has cors: { credentials: true }
  
  // Optional but useful for debugging
  autoConnect: true,  // Connect automatically (default true)
});

apiWebSocket.on('connect', () => {
  console.log('Connected to WebSocket');
});

apiWebSocket.on('disconnect', () => {
  console.log('Disconnected from WebSocket');
});

apiWebSocket.on('error', error => {
  console.error('WebSocket error:', error);
});

apiWebSocket.on('connect_error', error => {
  console.error('WebSocket connection error:', error);
});

apiWebSocket.on('reconnect', (attemptNumber) => {
  console.log('Reconnected after', attemptNumber, 'attempts');
});

apiWebSocket.on('ping', () => {
  console.log('Ping received');

  // Send pong back to server
  apiWebSocket.emit('pong');
});

export const apiWebSocketEmit = (event, payload) => apiWebSocket.emit(event, payload);

export const apiWebSocketCallback = (event, callback) => apiWebSocket.on(event, data => callback(data));
