import { identifyOnSocketConnection } from 'bootstrap/state-listeners/auth';

import { EVENT, IDENTIFICATION_INTERVAL } from '../../constants';

let identifyInterval;
export default ({ apiWebSocket, apiWebSocketEmit }) => {
  apiWebSocket.on(EVENT.RECEIVED.RECONNECT, () => {
    if (identifyInterval) clearInterval(identifyInterval);
    identifyOnSocketConnection();
    identifyInterval = setInterval(identifyOnSocketConnection, IDENTIFICATION_INTERVAL);
  });

  apiWebSocket.on(EVENT.RECEIVED.DISCONNECT, () => {
    clearInterval(identifyInterval);
  });
};
