import debounce from 'lodash/debounce';

import { onPatch } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { apiWebSocketEmit, apiWebSocketCallback } from '../../services/api-websocket';

import { EVENT } from '../../bootstrap/api-websocket/constants';
const { EMITTED: { IDENTIFY } } = EVENT;

export const identifyOnSocketConnection = debounce(() => {
  const { auth } = stores.global;

  console.log('Identifying on socket connection', !!auth.activeToken);

  if (auth.activeToken) apiWebSocketEmit(IDENTIFY, { token: auth.activeToken });
}, 1000);

export const bootstrap = () => {
  const {
    auth,
    users
  } = stores.global;

  // Watch for changes to auth token on users.
  onPatch(users, (patch) => {
    if (patch.path === '/activeToken') {
      auth.setValue('activeToken', patch.value);
      localStorage.setItem('activeToken', patch.value);
    }
  });

  onPatch(auth, (patch) => {
    if (patch.path === '/activeToken') setTimeout(() => {
      identifyOnSocketConnection();

      users.getCurrent();
    });
  });

  apiWebSocketCallback('reconnect', () => {
    identifyOnSocketConnection();
  });

  // Get Token from local storage.
  const localStorageAuthToken = localStorage.getItem('activeToken');
  if (localStorageAuthToken) auth.setValue('activeToken', localStorageAuthToken);

  const localStorageUserByToken = localStorage.getItem('userDataById');
  if (localStorageUserByToken) {
    users.setValue('userDataById', JSON.parse(localStorageUserByToken));
  }
  setTimeout(() => auth.setValue('hasCheckedLocalStorage', true), 400);
};
