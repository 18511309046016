import { get as _get } from 'lodash';

import stores from 'bootstrap/store';
const videoConferencesStore = stores.global.videoConferences;
const videoConferenceInvitationsStore = stores.global.videoConferenceInvitations;


export default (videoConference) => {
  const purpose = _get(videoConference, 'purpose.name');

  // TODO get rid of all of these
  if (videoConferencesStore.pendingInvitations.includes(videoConference)) {
    const videoConferenceInvitationId = _get(videoConference, 'videoConferenceInvitations[0].id');
    return videoConferenceInvitationsStore.acceptInvitation({ purpose, videoConferenceInvitationId });
  } else if (videoConferencesStore.activeConferences.includes(videoConference)) {
    console.log('HELLO HELLO');
    return videoConferencesStore.connect({ purpose, videoConferenceId: videoConference.id });
  }

  return videoConferencesStore.connect({ purpose, videoConferenceId: videoConference.id });

  // return Promise.reject('Could not connect to video conference: it ');
};
